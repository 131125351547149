import * as http from "./http";
import { APP_SERVER_URL, APP_SERVER_PORT } from "../environment";

export const isDemoSite = false;

const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
         result = `http://localhost:3009/`;
    }
    else {
        
        result = `https://atsapi.stglobaltech.com/`;
        // result = `https://api.apexlearning-academy.com/`

    }
    //result = `https://atsapi.stglobaltech.com/`;
    //result = `https://atsapi.stglobaltech.com/`;
   // result = `https://atsapi.stglobaltech.com/`;
//  result = `http://20.81.193.95:3009/`;
//result = `http://20.81.193.95:3009/`;
  //result = `http://52.172.190.126:3009/`;
 // result = `http://52.172.190.126:3009/`;
    return result;
};
export const APIUrl = getApi();


export const padDate = (data, field) => {
    if (data[field]) {
        if (data[field].length == 10) {
            data[field] = data[field] + "T00:00:00.000Z";
        }
    }
    return data;
}
export const get_dashboard = () => {
    let api = APIUrl + `users/dashboard`
    return http.get(api).then(response => {
        return response;
    });
}
export const getCandidateProgress = (id) => {
    let api = `${APIUrl}/candidates/progress/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const PrintPo = (id) => {
    let api = `${APIUrl}/agent-pos/printpo/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getCandidates = (data) => {
    let api = `${APIUrl}/candidates/getcandidates`;
    // let data:any = {
    //     UserID , RoleTypeID , FilterCondition,StartIndex, PageSize
    // };
    return http.post(api,data).then(response => {
        return response;
    });
}
export const get_dashboardV1 = (data) => {
    let api = APIUrl + `users/dashboardV1`
    return http.post(api,data).then(response => {
        return response;
    });
}
export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}
export const getResource = (resource) => {
    let api = `${APIUrl}${resource}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}

export const innerUploadImportFile = (field, data, daid, filename, tenantid, facilityid, createdby) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}fileimport?daid=${daid}&filename=${filename}&tenantid=${tenantid}&facilityid=${facilityid}&createdby=${createdby}`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const UploadImportFile = async (field, data, daid, filename, tenantid, facilityid, createdby) => {
    if (data && data.rawFile) {
        let response = await innerUploadImportFile(field, data, daid, filename, tenantid, facilityid, createdby);
        return response;
    }
    return data;
}

export const getRequirementDetLogs = (requirementDetId) => {
    let api = APIUrl + `requirements-details/getrequirementDetLogs/`+requirementDetId;
    return http.get(api).then(response => {
        return response;
    });
}
export const uploadInboundImportFile = (field, data, daid, filename, tenantid, facilityid, createdby) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}inboundimport?inboundid=${daid}&filename=${filename}&tenantid=${tenantid}&facilityid=${facilityid}&createdby=${createdby}`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const daClosureImportFile = (field, data, dahdrid, filename, tenantid, facilityid, createdby) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}daclosureimport?dahdrid=${dahdrid}&filename=${filename}&tenantid=${tenantid}&facilityid=${facilityid}&createdby=${createdby}`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}

export const getInboundUploadLogs = (id) => {
    let api = `${APIUrl}/ib-upload-logs?&filter[where][ibUploadId]=${id}&filter[order]=createdDate%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}


export const getInboundDetails = (id) => {
    let api = `${APIUrl}/ib-upload-details?&filter[where][ibUploadId]=${id}&filter[order]=createdDate%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}


export const getMheTasks = (taskTypeId = "", activityId = "", statusId = "") => {
    //let api = `${APIUrl}/mhe-tasks?&filter[order]=createdDate%20ASC&filter[include][]=taskTypeId`;
    let api = `${APIUrl}/mhe-tasks?&filter[order]=createdDate%20ASC`;

    if (taskTypeId != "") {
        api = `${api}&filter[where][taskTypeId]=${taskTypeId}`;
    }
    if (activityId != "") {
        api = `${api}&filter[where][activityTypeId]=${activityId}`;
    }
    if (statusId != "") {
        api = `${api}&filter[where][statusId]=${statusId}`;
    }
    return http.get(api).then(response => {
        return response;
    });
}


export const confirmInboundUploadLogs = (id) => {
    let api = `${APIUrl}/ib-upload-logs/confirm/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}



export const mheTaskStatus = (StationID, StatusID, TaskID, TaskTypeID,
    IsPickLocationInUse, IsPlaceLocationInUse, MainPriorities,
    SysPriorities, FromDate, ToDate) => {
    let api = `${APIUrl}/reports/mhetaskstatus`;
    let data: any = {
        StationID,
        StatusID,
        TaskID,
        TaskTypeID,
        IsPickLocationInUse,
        IsPlaceLocationInUse,
        MainPriorities,
        SysPriorities,
        FromDate,
        ToDate
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const CreateEmployment = (data) => {
    let api = `${APIUrl}/requirements-details/createemployment`; 
    return http.post(api, data).then(response => {
        return response;
    });
}
export const ConvView = () => {
    let api = `${APIUrl}/reports/convview`;
    let data: any = {
       
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const filterDadatewise = (date, tenantId, facilityId) => {
    let api = `${APIUrl}/das/filterDadatewise`;
    let data: any = {
        date, tenantId, facilityId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const customersByDA = (id) => {
    let api = `${APIUrl}/customersbyda/${id}`;
   
    return http.get(api).then(response => {
        return response;
    });
}

export const ordersByDA = (id, customerId) => {
    let api = `${APIUrl}/ordersbyda/${id}?customerId=${customerId}`;
   
    return http.get(api).then(response => {
        return response;
    });
}

export const getordersforcancel = (daId, customerId, orderNo, userId) => {
    let api = `${APIUrl}/mhe-tasks/getordersforcancel`;
    let data: any = {
        daId, customerId, orderNo, userId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const cancelOrderMultiple = (daId, customerId, orderNo, userId) => {
    let api = `${APIUrl}/mhe-tasks/cancelOrder`;
    let data: any = {
        daId, customerId, orderNo, userId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}



export const daWiseOrderStatus = (ReportType, CustomerID, OrderNo, FromDate, ToDate) => {
    let api = `${APIUrl}/reports/dawiseorderstatus`;
    let data: any = {
        ReportType,
        CustomerID,
        OrderNo,
        FromDate,
        ToDate
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packSizeChangeLog = (FromDate, ToDate) => {
    let api = `${APIUrl}/reports/packsizechangelog`;
    let data: any = {
        FromDate,
        ToDate
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getMheTask = (stationId, tenantId, facilityId, userId) => {
    let api = `${APIUrl}/mhe-tasks/getMheTask`;
    let data: any = {
        stationId,
        tenantId,
        facilityId,
        userId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const updateMheTask = (taskId, stationId, scanCode, scanValue, userId) => {
    let api = `${APIUrl}/mhe-tasks/updateTaskDetail`;
    let data: any = {
        taskId,
        stationId,
        scanCode,
        scanValue,
        userId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}




export const inboundUploadStats = (id) => {
    let api = `${APIUrl}/ib-upload-logs/stats/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const clearInboundUploadLogs = (id) => {
    let api = `${APIUrl}/ib-upload-logs/clear/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}



export const getDaUploadLogs = (id) => {
    let api = `${APIUrl}/da-upload-logs?&filter[where][daid]=${id}&filter[order]=createdDate%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getDaClosureUploadLogs = (id) => {
    let api = `${APIUrl}/invoice-upload-logs?&filter[where][invoiceId]=${id}&filter[order]=createdDate%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getStationPlans = (stationId) => {
    let api = `${APIUrl}/pick-plans/getstationplans/${stationId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAssignedPlans = (stationId, userId) => {
    let api = `${APIUrl}/pick-plans/getAssignedPlan/${stationId}/${userId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const pickPlanScanUpdate = (stationId, customerCode, mheTaskId, orderNo, pickBin, itemCode, looseQty, userId) => {
    let api = `${APIUrl}/pick-plans/updateData`;
    let data: any = {
        stationId,
        customerCode,
        mheTaskId,
        orderNo,
        pickBin,
        itemCode,
        looseQty,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const releasepickbin = (stationId, pickBin, userId) => {
    let api = `${APIUrl}/pick-plans/releasepickbin`;
    let data: any = {
        stationId,
        pickBin,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const pickShortReview = (stationId, mheTaskId, userId) => {
    let api = `${APIUrl}/pick-plans/pickreview`;
    let data: any = {
        stationId,
        mheTaskId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const releasePallet = (stationId, mheTaskId, returnFlag, userId) => {
    let api = `${APIUrl}/pick-plans/updatePallet`;
    let data: any = {
        stationId,
        mheTaskId,
        returnFlag,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const pickshort = (stationId, mheTaskId, customerCode, orderNo, itemCode, userId) => {
    let api = `${APIUrl}/pick-plans/pickshort`;
    let data: any = {
        stationId,
        customerCode,
        mheTaskId,
        orderNo,
        itemCode,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updatePackSize = (stationId, itemCode, mheTaskId, packSize, userId) => {
    let api = `${APIUrl}/pick-plans/updatePackSize`;
    let data: any = {
        stationId,
        itemCode,
        mheTaskId,
        packSize,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getAssignedPlanDetail = (stationId, mheTaskId, customerCode, orderNo, userId, viewType) => {
    let api = `${APIUrl}/pick-plans/getAssignedPlanDetail/${stationId}/${mheTaskId}/${customerCode}/${orderNo}/${userId}/${viewType}`;
    return http.get(api).then(response => {
        return response;
    });
}

/* ============================ PACK==================================*/
export const packerScanPickbin = (stationId, pickBin, userId) => {
    let api = `${APIUrl}/pack-plans/scanpickbin`;
    let data: any = {
        stationId,
        pickBin,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packerGetTask = (stationId, userId) => {
    let api = `${APIUrl}/pack-plans/gettask`;
    let data: any = {
        stationId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packScan = (stationId, cartonNo, itemCode, packSize, looseQty, looseQtyFlag, pickBinId, serials, userId) => {
    let api = `${APIUrl}/pack-plans/packscan`;
    let data: any = {
        stationId,
        cartonNo,
        itemCode,
        packSize,
        looseQty,
        looseQtyFlag,
        pickBinId,
        serials,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packshort = (stationId, pickBinId, itemCode, userId) => {
    let api = `${APIUrl}/pack-plans/packshort`;
    let data: any = {
        stationId,
        pickBinId,
        itemCode,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const cartonView = (stationId, userId) => {
    let api = `${APIUrl}/pack-plans/cartonview`;
    let data: any = {
        stationId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const validateSerialNo = (pickBinId, itemCode, serialNo) => {
    let api = `${APIUrl}/pack-plans/validateserialno`;
    let data: any = {
        pickBinId,
        itemCode,
        serialNo
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packShortReview = (stationId, pickBinId, userId) => {
    let api = `${APIUrl}/pack-plans/packreview`;
    let data: any = {
        stationId,
        pickBinId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const releaseCarton = (stationId, cartonNo, weight, fold, userId) => {
    let api = `${APIUrl}/pack-plans/cartonrelease`;
    let data: any = {
        stationId,
        cartonNo,
        weight,
        fold,
        userId
    }
    console.log(data, "==cartonrelease==");
    console.log(fold, "==fold==");
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packingReleasePickbin = (stationId, pickBinId, emptyFlag, userId) => {
    let api = `${APIUrl}/pack-plans/releasepickbin`;
    let data: any = {
        stationId,
        pickBinId,
        emptyFlag,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}


/* ============================ AUDIT PACK==================================*/

export const auditPackerScanPickbin = (stationId, pickBin, userId) => {
    let api = `${APIUrl}/audit-tasks/scanpickbin`;
    let data: any = {
        stationId,
        pickBin,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditPackerGetTask = (stationId, userId) => {
    let api = `${APIUrl}/audit-tasks/gettask`;
    let data: any = {
        stationId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditPackerUpdateLocation = (pickBinId, locationCode) => {
    let api = `${APIUrl}/audit-tasks/packshort/updateLocation`;
    let data: any = {
        pickBinId,
        locationCode
    }
    return http.post(api, data).then(response => {
        return response;
    });
}



export const auditPackScan = (stationId, cartonNo, itemCode, packSize, looseQty, looseQtyFlag, pickBinId, serials, userId) => {
    let api = `${APIUrl}/audit-tasks/packscan`;
    let data: any = {
        stationId,
        cartonNo,
        itemCode,
        packSize,
        looseQty,
        looseQtyFlag,
        pickBinId,
        serials,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditPackshort = (stationId, pickBinId, itemCode, userId) => {
    let api = `${APIUrl}/audit-tasks/packshort`;
    let data: any = {
        stationId,
        pickBinId,
        itemCode,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditCartonView = (stationId, userId) => {
    let api = `${APIUrl}/audit-tasks/cartonview`;
    let data: any = {
        stationId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateAuditAccess = (tenantId, facilityId, linkId, userId, desicioncode) => {
    let api = `${APIUrl}/audit-tasks/updateexcessdecision`;
    let data: any = {
        tenantId,
        facilityId,
        linkId,
        userId,
        desicioncode
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditValidateSerialNo = (pickBinId, itemCode, serialNo) => {
    let api = `${APIUrl}/audit-tasks/validateserialno`;
    let data: any = {
        pickBinId,
        itemCode,
        serialNo
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditPackShortReview = (stationId, pickBinId, userId) => {
    let api = `${APIUrl}/audit-tasks/packreview`;
    let data: any = {
        stationId,
        pickBinId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditReleaseCarton = (stationId, cartonNo, weight, fold, userId) => {
    let api = `${APIUrl}/audit-tasks/cartonrelease`;
    let data: any = {
        stationId,
        cartonNo,
        weight,
        fold,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const packingReleasePickbin_audit_pack = (stationId, pickBinId, emptyFlag, userId) => {
    let api = `${APIUrl}/audit-tasks/releasepickbin_audit_pack`;
    let data: any = {
        stationId,
        pickBinId,
        emptyFlag,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

/** ============================== END AUDIT PACK======================= */

export const getdarepriortization = (data) => {
    let api = `${APIUrl}/das/getdarepriortization`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const setdarepriortization = (data) => {
    let api = `${APIUrl}/das/setdarepriortization`;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const closeAllDas = (facilityid) => {
    let api = `${APIUrl}/das/closeall`;
    let data:any = {
        facilityid
    };
    return http.post(api, data).then(response => {
        return response;
    });
}



export const getDaDetails = (id) => {
    let api = `${APIUrl}/da-details?&filter[where][daid]=${id}&filter[order]=createdDate%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const confirmDaUploadLogs = (id) => {
    let api = `${APIUrl}/da-upload-logs/confirm/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const daUploadStats = (id) => {
    let api = `${APIUrl}/da-upload-logs/stats/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const clearDaUploadLogs = (id) => {
    let api = `${APIUrl}/da-upload-logs/clear/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}


export const confirmDaClosureUploadLogs = (id) => {
    let api = `${APIUrl}/daclosure/confirm/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const daClosureUploadStats = (id) => {
    let api = `${APIUrl}/daclosure/stats/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const clearDaClosureUploadLogs = (id) => {
    let api = `${APIUrl}/daclosure/clear/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}


export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const approveContent = (contentId, approvalFlag) => {
    let api = APIUrl + "content/approve_disapprove_content";
    let data: any = {
        id: contentId,
        status: approvalFlag
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const loginUser = (user) => {
    let api = APIUrl + "user/login";
    return http.post(api, user).then(response => {
        return response;
    });
}


export const loginUserByToken = (token) => {
    let api = APIUrl + "userById";
    let user: any = {
        token: token,
        StartIndex: 0,
        PageSize: 10,
    };
    return http.post(api, user).then(response => {
        return response;
    });
}

export const deleteRecord = async (path, id) => {
    let api = APIUrl + path + "/" + id;
    return await http.del(api).then(response => {
        return response;
    });
}

export const getLifeOrderBrokrage = (id) => {
    let api = `${APIUrl}/life-order-brokrages/getbrokrageSummary/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getOrderBrokrage = (id) => {
    let api = `${APIUrl}/order-brokrages/getbrokrageSummary/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getbrokrageSummary = (id) => {
    let api = `${APIUrl}/order-brokrages/getbrokrageInsurerSummary/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getbrokrageInsurerDetail = (id) => {
    let api = `${APIUrl}/order-brokrages/getbrokrageInsurerDetail/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getSharePerc = (id) => {
    let api = `${APIUrl}/order-premiums/getSharePerc/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getEntiyRecord = (resource, id) => {
    let api = APIUrl + resource + "/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateEntiyRecord = (resource, id, data) => {
    debugger
    var keys = Object.keys(data);
    keys.forEach((item) => {
      if (data[item] == null) {
        delete data[item];
      }
    });
    let api = APIUrl + resource + "/" + id;
    return http.put(api, data).then(response => {
        return response;
    });
}


export const addEntiyRecord = (resource, data) => {

     
    if (data.hasOwnProperty("createdBy") && data.createdBy != null) {
        data.createdBy = parseInt(data.createdBy);
    }

    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addSendEmail = ( data) => {

    

    let api = APIUrl + "candidates/sendbulkemail";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const cartonprint = (id, count) => {
    let api = `${APIUrl}carton-prints/cartonprint/${id}/${count}`;
    return http.post(api).then(response => {
        return response;
    });
}

export const searchBarcodes = (cartonId, start, end) => {
    let api = `${APIUrl}carton-print-bar-codes/?filter[where][cartonId]=${cartonId}&filter[where][and][0][cartonSerialNo][gte]=${start}&filter[where][and][1][cartonSerialNo][lte]=${end}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getPackCarton = (cartonId) => {
    let api = `${APIUrl}pack-cartons/?filter[where][cartonId]=${cartonId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const ChangePassword = (data) => {
    let api = APIUrl + "users/update_password";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const reviewAssignment = (data) => {
    let api = APIUrl + "assignment/review_assignment";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getSubjectsForStudent = () => {
    let api = APIUrl + "subject/get_subjects_for_logged_user";
    return http.get(api).then(response => {
        return response;
    });
}
export const getPolicy = (insurerid, fromdate, todate) => {
    let api = APIUrl + `policy-invs/getpolicy/${insurerid}/${fromdate}/${todate}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getContentPageForSubject = (subjectId) => {
    let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccountToken = (accountId) => {
    let api = APIUrl + `account/get_account_token`;
    let data: any = { "accountId": accountId };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getSectionsForClass = (classId) => {
    //let api = APIUrl + `section/${classId}`;
    var filter = { "class_id=": classId };
    const filterString = JSON.stringify(filter);
    let api = `${APIUrl}/section?filter=${filterString}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getRateMaster = (id) => {
    let api = APIUrl + `rate-masters?filter[offset]=0&filter[limit]=2500000&filter[order]=name%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getBrokrageData = (orderPaymentId) => {
    let api = APIUrl + `/order-brokrages/getbrokragedata/${orderPaymentId}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getTaxHead = (id) => {
    let api = APIUrl + `tax-heads/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getBrokrageType = (id) => {
    let api = APIUrl + `lookups?filter[offset]=0&filter[limit]=25&filter[order]=id%20DESC&filter[where][type]=BT`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getLookupByCode = (code) => {
    let api = APIUrl + `lookups?filter[where][code]=${code}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getLookupByType = (type) => {
    let api = APIUrl + `lookups?filter[where][type]=${type}`;
    return http.get(api).then(response => {
        return response;
    });
}

/*========================Audit Tasks API=================================== */

export const getAuditTasks = (tenantId, facilityId, stationId, userId, activityId, taskTypeId) => {
    let api = APIUrl + `/audit-tasks/tasks`;
    let data: any = {
        tenantId,
        facilityId,
        stationId,
        userId,
        activityId,
        taskTypeId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const assignAuditTask = (stationId, userId, taskTypeId, linkId) => {
    let api = APIUrl + `/audit-tasks/assigntask`;
    let data: any = {
        stationId,
        userId,
        taskTypeId,
        linkId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getAuditTaskType = (stationId, userId) => {
    let api = APIUrl + `/audit-tasks/gettasktype`;
    let data: any = {
        stationId,
        userId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const cancelOrder = (tenantId, facilityId, linkId, activityTypeId, taskTypeId) => {
    let api = APIUrl + `/audit-tasks/cancelorder`;
    let data: any = {
        tenantId, facilityId, linkId, activityTypeId, taskTypeId
    };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const updateOrderPriority = (stationId, userId, linkId, activityTypeId, taskTypeId, orderNo) => {
    let api = APIUrl + `/audit-tasks/updateOrderPriority`;
    let data: any = {
        stationId, userId, linkId, activityTypeId, taskTypeId, orderNo
    };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getAuditAssignedPlans = (stationId, userId) => {
    let api = `${APIUrl}/audit-tasks/getAssignedPlan/${stationId}/${userId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAuditAssignedPlanDetail = (stationId, mheTaskId, customerCode, orderNo, userId, viewType) => {
    let api = `${APIUrl}/audit-tasks/getAssignedPlanDetail/${stationId}/${mheTaskId}/${customerCode}/${orderNo}/${userId}/${viewType}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const auditPickPlanScanUpdate = (stationId, customerCode, mheTaskId, orderNo, pickBin, itemCode, looseQty, userId) => {
    let api = `${APIUrl}/audit-tasks/updateData`;
    let data: any = {
        stationId,
        customerCode,
        mheTaskId,
        orderNo,
        pickBin,
        itemCode,
        looseQty,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditUpdateLocation = (mheTaskId, locationCode) => {
    let api = `${APIUrl}/audit-tasks/updateLocation`;
    let data: any = {
        mheTaskId,
        locationCode,
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const releasepickbin_audit_pick = (stationId, pickBinId, userId) => {
    let api = `${APIUrl}/audit-tasks/releasepickbin_audit_pick`;
    let data: any = {
        stationId,
        pickBinId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const auditPickShortReview = (stationId, mheTaskId, userId) => {
    let api = `${APIUrl}/audit-tasks/pickreview`;
    let data: any = {
        stationId,
        mheTaskId,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditReleasePallet = (stationId, mheTaskId, returnFlag, userId) => {
    let api = `${APIUrl}/audit-tasks/updatePallet`;
    let data: any = {
        stationId,
        mheTaskId,
        returnFlag,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditPickshort = (stationId, mheTaskId, customerCode, orderNo, itemCode, userId) => {
    let api = `${APIUrl}/audit-tasks/pickshort`;
    let data: any = {
        stationId,
        customerCode,
        mheTaskId,
        orderNo,
        itemCode,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const auditUpdatePackSize = (stationId, itemCode, mheTaskId, packSize, userId) => {
    let api = `${APIUrl}/audit-tasks/updatePackSize`;
    let data: any = {
        stationId,
        itemCode,
        mheTaskId,
        packSize,
        userId
    }
    return http.post(api, data).then(response => {
        return response;
    });
}


/* ================================AUDIT task APi End====================================================*/




export const GetDashboardHoliday = () => {
    let api = `${APIUrl}employees/dashboardholiday`;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateUserAccountMapping = (data) => {
    let api = APIUrl + "/useraccountmaps/mapaccount";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateUserRoleMapping = (data) => {
    let api = APIUrl + "/userrolemap/maprole";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserDeparmentMapping = (data) => {
    let api = APIUrl + "/userdepartmentmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserfDeparmentMapping = (data) => {
    let api = APIUrl + "/userfullfillmentdeptmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}


export const updateaccountprice = (data) => {
    let api = APIUrl + "/accountlabpartnertestprices/mapprice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapglobalRouting = (data) => {
    let api = APIUrl + "/globalroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const mheCancelTask = (data) => {
    let api = APIUrl + "/mhe-tasks/canceltask";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const mheCreateReturnTask = (taskId, userId) => {
    let data: any = {
        taskId,
        userId
    }
    let api = APIUrl + "/mhe-tasks/addreturntask";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateempty = (taskId, stationId, userId) => {
    let data: any = {
        taskId,
        stationId,
        userId
    }
    let api = APIUrl + "/mhe-tasks/updateempty";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateLogs = (taskId, stationId, scanMode, userId) => {
    let data: any = {
        taskId,
        stationId,
        scanMode,
        userId
    }
    let api = APIUrl + "/mhe-tasks/updateLogs";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const stationBreak = (stationId, flag, userId) => {
    let data: any = {
        stationId,
        flag,
        userId
    }
    let api = APIUrl + "/stations/hold";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getStationBreakStatus = (stationId) => {
    let api = `${APIUrl}/stations/status/${stationId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const mapTestRouting = (data) => {
    let api = APIUrl + "/testroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const MapGroupTest = (data) => {
    let api = APIUrl + "/testgroupmaps/maptest";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const fetchGridData = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    console.log(api);
    return http.get(api).then(response => {

        return response;
    })
}
export const getPatientData = (id) => {
    let api = APIUrl + "/patients/" + id;
    return http.get(api).then(response => {
        return response;
    })
}
export const addFromSetup = (id, config) => {
    let api = APIUrl + "/realiz-ords/addfromsetup/" + id + "/" + config;
    return http.get(api).then(response => {
        return response;
    })
}
export const exportToCSV = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    return http.get(api).then(response => {

        return response;
    })
}

export const loadReport = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getdata = (resource) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=250&filter[order]=id%20DESC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getdata_v1 = (resource) => {
    let api = `${APIUrl}${resource}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getdataWithOrder = (resource, orderField, sortDirection) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=25000&filter[order]=${orderField}%20${sortDirection}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getOrderHeaderData = (id) => {
    let api = APIUrl + "orders/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getCustomerByPolicyId = (id) => {
    let api = APIUrl + "orders/customer/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateRolerights = (data) => {
    let api = APIUrl + "role-types/rights";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRoleType = (id, data) => {
    let api = APIUrl + `role-types/${id}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const getmodules = (user_id) => {
    let api = APIUrl + "employees/getmodules/" + user_id;
    return http.get(api).then(response => {
        return response;
    });
}

export const updateUserLogs = (userId, stationId, flag) => {
    let api = APIUrl + "/users/updateuserlogs";
    let data: any = {
        userId,
        stationId,
        mheId: "",
        flag
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getfunctionbyroletypeV10 = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletypev10/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getfunctionbyroletype = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletype/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}


export const getGetHospitalDataForPrint = (id) => {
    let api = `${APIUrl}hospitals/printdata/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const realizationOrderPrint = (id) => {
    let api = `${APIUrl}realiz-ords/printdata/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const realizationOrderPrintTPADeclaration = (id, tpaId) => {
    let api = `${APIUrl}realiz-ords/printdatatpadeclaration/${tpaId}/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addCorporate = (data) => {
    let api = APIUrl + "corporates";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const addRecord = (data, resource) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const deleteRealizationDocument = (id) => {
    let api = APIUrl + "/realiz-ord-dets/" + id;
    return http.del(api).then(response => {
        return response;
    });
}

export const gettaxheads = (id) => {
    let api = APIUrl + "tax-heads/" + id;
    return http.get(api).then(response => {
        return response;
    });
}


export const getInvoiceData = (invoiceid) => {
    let api = APIUrl + `/hospital-invoice/getinvoicedata/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getInvoiceDataForPrint = (invoiceid) => {
    let api = APIUrl + `/policy-invs/printinvoice/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}



export const getpayslipPrint = (id) => {
    let api = APIUrl + `/employee-sal/printpayslip/${id}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getOrderDocuments = (id) => {
    let api = APIUrl + `/order-documents?filter[offset]=0&filter[limit]=250&filter[order]=id%20ASC&filter[where][ordId]=${id}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getOrederEmailData = (id, templateId) => {
    let api = APIUrl + `/orders/emaildata/${id}/${templateId}`
    return http.get(api).then(response => {
        return response;
    });
}


export const addupdateInvoice = (data) => {
    let api = APIUrl + "policy-invs/createupdateinvoice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createupdatesalary = (data) => {
    let api = APIUrl + "employee-sal/createupdatesalary";
    return http.post(api, data).then(response => {
        return response;
    });
}



export const updateleave = (data) => {
    let api = APIUrl + "/employee-leaves/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateAttendance = (data) => {
    let api = APIUrl + "/employees/attendanceentry";
    return http.post(api, data).then(response => {
        return response;
    });

}
export const updatebrokrages = (data) => {
    let api = APIUrl + "/order-brokrages/updatebrokrages";
    return http.post(api, data).then(response => {
        return response;
    });

}
export const dashboard = (userID, fromDate = "", toDate = "") => {
    let api = APIUrl + "/reports/dashboard";
    let data: any = {
        userID, fromDate, toDate
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getDashboard_Data = (path, UserID, FromDate = "", ToDate = "") => {
    let api = `${APIUrl}/reports/${path}`;
    let data: any = {
        UserID, FromDate, ToDate
    };
    return http.post(api, data).then(response => {
        return response;
    });
}



export const searchcandidate = (data) => {
    let api = `${APIUrl}/submissions/searchcandidate/`;

    return http.post(api, data).then(response => {
        return response;
    });
}


export const addCandidate = (data) => {
    let api = `${APIUrl}/submissions/addCandidate/`;

    return http.post(api, data).then(response => {
        return response;
    });
}



export const addPaymentSch = (id) => {
    let api = APIUrl + "order-premiums/createOrdPymntNyPremId/" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getTestQuestion = (id) => {
    let api = APIUrl + "test-questions?filter[offset]=0&filter[limit]=1000&filter[order]=questionNumber%20ASC&filter[where][courseTopicId]=" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getTestQuestionOption = (id) => {
    let api = APIUrl + "test-question-options?filter[offset]=0&filter[limit]=1000&filter[order]=optionNumber%20ASC&filter[where][testQuestionId]=" + id;
    return http.get(api).then(response => {
        return response;
    });
}
export const getTestQuestionOptions = (id) => {
    let api = APIUrl + "test-question-option-values?filter[offset]=0&filter[limit]=1000&filter[order]=id%20ASC&filter[where][testQuestionOptionId]=" + id;
    return http.get(api).then(response => {
        return response;
    });
}

export const GetRequirementStages = (requirementId,type ) => {
    let api = APIUrl + `requirements-details/getRequirementStages/${requirementId}/${type}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const ApproveImportCandidate = (data ) => {
    let api = APIUrl + `import-candidates/approve`;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRecord = (resource, data) => {
    if (data.hasOwnProperty("dob") && data.dob == null) {
        data.dob = "1900-01-01T03:09:32.033Z";
    }
    if (data.hasOwnProperty("dob") && data.dob != null) {
        if (!data.dob.toString().includes('T03:09:32.033Z')) {
            data.dob = data.dob + 'T03:09:32.033Z';
        }

    }
    if (data.hasOwnProperty("dealDate") && data.dob != null) {
        if (!data.dealDate.toString().includes('T03:09:32.033Z')) {
            data.dealDate = data.dealDate + 'T03:09:32.033Z';
        }

    }
    if (data.hasOwnProperty("paymentDate") && data.paymentDate != null) {
        if (!data.paymentDate.toString().includes('T03:09:32.033Z')) {
            data.paymentDate = data.paymentDate + 'T03:09:32.033Z';
        }

    }

    if (data.hasOwnProperty("openDate") && data.dob != null) {
        if (!data.openDate.toString().includes('T03:09:32.033Z')) {
            data.openDate = data.openDate + 'T03:09:32.033Z';
        }

    }
    let api = APIUrl + "/" + resource + "/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });

    

}


