import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,useRedirect
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";
import { Authorizer } from "../../common/Authorizer";
import { Button } from "@material-ui/core";


const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="Subject Line" source="subjectLine~like" alwaysOn />
    </Filter>
);


export const ActivityList = (props) => {
  //let propsObj = Authorizer(props,"List"); 
  //let propsObj = Authorizer(props, "Edit");
  const redirect=useRedirect();
      let propsObj = { ...props };
          if (propsObj.permissions) {
              let resourcename = `/${props.resource}`;
              let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
              if (myPermissions.length > 0) {
                  let p = myPermissions[0];
                  propsObj.hasList = (p.View == 1) ? true : false;
                  propsObj.hasShow = (p.View == 1) ? true : false;
                  propsObj.hasEdit = (p.Edit == 1) ? true : false;
                  propsObj.hasDelete = (p.Delete == 1) ? true : false;
                  propsObj.hasCreate = (p.Create == 1) ? true : false;
                  propsObj.hasExport = (p.Export == 1) ? true : false;
                  propsObj = { ...propsObj, ...p }
              }
          }
      
          let userId;
          userId = localStorage.getItem("userId");
          if (userId) {
            userId = parseInt(userId);
          }
          const CustomEdit = (props) => {
            let { record } = props;
          
          
            return <div style={{ display: 'flex', flexDirection: 'row' }} >
               
                
                
                {propsObj.hasEdit && <Button style={{ width: 20, fontSize: 9, backgroundColor: 'grey', color: 'white', marginLeft: 10 }}
                    color="primary" disableElevation
                    size="medium"
          
                    onClick={() => {redirect(`/interviews/${record.requirementId}/5`);
                        
                    }}
                >Edit</Button>}  
           
          
            </div>
          
          }
  return (
    <List  {...propsObj}  exporter={false} bulkActionButtons={false}     filters={<Filters />} 
    sort={{ field: "requisitionNo", order: "DESC" }}

     >
      <Datagrid rowClick={false} > 
        {/* {propsObj.hasEdit && <EditButton />}  */}
        {propsObj.hasEdit &&<CustomEdit title="Action"  />}
         <TextField source="requisitionNo" label="Requisition No" />
                <TextField source="subjectLine" label="Subject Line" />
                
                <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                
                <ReferenceField source="candidateId" reference="candidates" label="Candidate" link={false}>
                  <TextField source="name" />
                </ReferenceField>
                
                
                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                
                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                
                <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                  <TextField source="userName" />
                </ReferenceField>
                
                <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
        
      </Datagrid>
    </List>
  );
};
