import React, { useEffect, useState } from "react";
import {
    useRefresh,
    required,
    useNotify,
    useRedirect,
    minLength,
    maxLength,
    minValue,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    ReferenceManyField,
    Datagrid,
    FormTab,
    AutocompleteInput,
    Edit, TabbedForm, TextInput, SelectInput, BooleanField,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, useQueryWithStore, FormDataConsumer, List, Filter, SearchInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
import RichTextInput from 'ra-input-rich-text';
import VSDeleteButton from "../../common/CDeleteButton";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import StageEdit from "./StageEdit";
import StageCreate from "./StageCreate";
import { Drawer } from '@material-ui/core';
import { SkillCreate } from "./SkillsCreate";
import SkillEdit from "./SkillEdit";
import RecruiterCreate from "./RecruiterCreate";
import { EmployeeFields } from "./EmployeeFields";
import CandidateActionView from "./CandidateActionView";
import CandidateActionCreate from "./CandidateActionCreate";
import CandidateOnBoarding from "./CandidateOnBoarding";
import { DisplayDateTime } from "../../common/DisplayDate";
const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "90%"
    },
}));
export const ActivityEdit = (props) => {
    const classes = useStyles({});
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [isAddStage, setIsAddStage] = useState(false);
    const [isEditStage, setIsEditStage] = useState(false);
    const [isConfirmOnBoarding, setIsConfirmOnBoarding] = useState(false);
    const [isAddCandidate, setIsAddCandidate] = useState(false);
    const [isViewCandidate, setIsViewCandidate] = useState(false);
    const [isEditCandidate, setIsEditCandidate] = useState(false);
    const [isAddSkill, setIsAddSkill] = useState(false);
    const [isEditSkill, setIsEditSkill] = useState(false);
    const [IsEditRecord, setIsEditRecord] = useState(null);
    const [requirementId, setRequirementId] = useState(0);
    const [locationCityId, setLocationCityId] = useState(0);
    const [workAuthorizationId, setWorkAuthorizationId] = useState(0);
    
    //let propsObj = Authorizer(props, "Edit");
    let propsObj = { ...props };
        if (propsObj.permissions) {
            let resourcename = `/${props.resource}`;
            let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
            if (myPermissions.length > 0) {
                let p = myPermissions[0];
                propsObj.hasList = (p.View == 1) ? true : false;
                propsObj.hasShow = (p.View == 1) ? true : false;
                propsObj.hasEdit = (p.Edit == 1) ? true : false;
                propsObj.hasDelete = (p.Delete == 1) ? true : false;
                propsObj.hasCreate = (p.Create == 1) ? true : false;
                propsObj.hasExport = (p.Export == 1) ? true : false;
                propsObj = { ...propsObj, ...p }
            }
        }
    
        let userId;
        userId = localStorage.getItem("userId");
        if (userId) {
          userId = parseInt(userId);
        }
    const onCancel = () => {
        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddSkill(false);
        setIsViewCandidate(false);
        setIsAddCandidate(false);
        setIsEditCandidate(false);
        setIsConfirmOnBoarding(false);
        refresh();

    }
    const onCloseForm = () => {

        setIsAddStage(false);
        setIsEditStage(false);
        setIsAddSkill(false);
        setIsViewCandidate(false);
        setIsAddCandidate(false);
        setIsEditCandidate(false);
        setIsConfirmOnBoarding(false);
        refresh();
        //loadNotificationDetail();
    }

    const CustomCandidate = (props) => {
        const { record } = props;
        const { data, loading, error } = useQueryWithStore({
            type: 'getOne',
            resource: 'candidates',
            payload: { id: record.candidateId },
        });

        if (loading) {
            return <div>Loading....</div>;
        }

        if (error) {
            console.error("Error fetching data:", error);
            return <div>Error loading data</div>;
        }

        if (record.typeId === 46) {
            return <div>{data.firstName} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        } else {
            return <div>{data.name} {data.lastName}</div>; // Replace 'firstName' with the actual property you want to display
        }
    }

    const CustomEdit = (props) => {
        let { record } = props;


        return <div style={{ display: 'flex', flexDirection: 'row' }} >
            {/* {record.statusId != 7 && record.statusId != 8 && <VSDeleteButton {...props} />} */}
            {/* {
                <Button style={{ marginLeft: 10, fontSize: 9, backgroundColor: '#02384d', color: 'white' }}
                    color="primary"
                    size="medium"
                    onClick={() => {
                        setIsEditRecord(record); setIsConfirmOnBoarding(true);
                    }}

                >{"Confirm"}</Button>} */}
            {propsObj.hasActionOnCandidates && userId==props.submissionCreatedBy &&
                  <VSDeleteButton {...props} />}
            
            {propsObj.hasActionOnCandidates && <Button style={{ width: 20, fontSize: 9, backgroundColor: '#02384d', color: 'white', marginLeft: 10 }}
                color="primary" disableElevation
                size="medium"

                onClick={() => {
                    setIsEditRecord(record); setIsEditCandidate(true);
                }}
            >{props.title}</Button>}

            <Button style={{ width: 10, fontSize: 9, backgroundColor: '#02384d', color: 'white', marginLeft: 10 }}
                color="primary" disableElevation
                size="medium"

                onClick={() => {
                    setIsEditRecord(record); setIsViewCandidate(true);
                }}
            >{"View"}</Button>


        </div>

    }

    const ModuleFilter = (props) => (
        
        <Filter {...props}>
            <ReferenceInput alwaysOn label="Search Candidate " variant="outlined" source="candidateId" 
            reference="vw-requirements-candidates" 
            filter={{ requirementId: requirementId }}
             sort={{ field: 'name', order: 'asc' }} fullWidth={true} 
            filterToQuery={searchText => ({ 'name~like': searchText })} 
            >
                <AutocompleteInput optionText="name"  variant="outlined" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
            </ReferenceInput>

        </Filter>
    );
    return (
        <>
            <Edit  {...propsObj} resource="requirements" title="Interview" >

                <TabbedForm variant="outlined" toolbar={<FormToolbar hideSave={true} {...props} mode={"Edit"} />}>
                    <FormTab value="DETAILS" label="Detail">
                        <ReferenceInput label="Client" source="clientId"
                            reference="clients"
                            disabled={true} sort={{ field: 'name', order: 'asc' }} filter={{ isActive: 1 }} fullWidth={true}   >
                            <SelectInput variant="outlined" optionText="name" />
                        </ReferenceInput>
                        <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} disabled={true} />
                        <TextInput source="subjectLine" fullWidth={true} label="Subject Line" validate={[required()]} formClassName={classes.last_inline_input} disabled={true} />

                        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
                            label="Location City" source="locationCityId" reference="cities" fullWidth={true} formClassName={classes.one_three_input}  >

                            <SelectInput variant="outlined" optionText="name" disabled={true} />

                        </ReferenceInput>
                        <BooleanInput source="isLocalOnly" validate={[required()]} initialValue={true} label="Is Local Only" formClassName={classes.two_three_input} disabled={true} />
                        <ReferenceInput label="Employment Type" source="employmentTypeId"
                            reference="lookups"
                            validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ type: 'EMPTP' }} fullWidth={true} formClassName={classes.last_three_input}  >
                            <SelectInput variant="outlined" optionText="name" disabled={true} />

                        </ReferenceInput>
                        <RichTextInput fullWidth={true} validate={required()} source="jobDetails" label="Job Details" formClassName={classes.first_inline_input}
                            toolbar={null}
                            disabled={true}
                            style={{ pointerEvents: 'none', backgroundColor: true ? '#f5f5f5' : 'white' }}
                        />
                        <RichTextInput fullWidth={true} validate={required()} source="skilDetails" label="Skill Details"
                            toolbar={null}
                            disabled={true}
                            style={{ pointerEvents: 'none', backgroundColor: true ? '#f5f5f5' : 'white' }}
                            formClassName={classes.last_inline_input} />
                        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
                            label="Workauthorization" source="workAuthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.first_inline_input}  >
                            <SelectInput variant="outlined" optionText="name" disabled={true} />
                        </ReferenceInput>

                        <ReferenceInput perPage={25} sort={{ field: 'userName', order: 'ASC' }}
                            filterToQuery={searchText => ({ 'userName~like': searchText })} variant="outlined"
                            label="Assigned By" source="assignedBy" reference="users" fullWidth={true} formClassName={classes.last_inline_input}  >
                            {/* <AutocompleteInput variant="outlined" optionText="userName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> */}
                            <SelectInput variant="outlined" optionText="userName" disabled={true} />
                        </ReferenceInput>

                    </FormTab>
                   {propsObj.hasViewSelectionProcess && <FormTab value="Other" label="Selection Process">
                        {/* <Button variant="contained" onClick={() => setIsAddStage(true)}
                            color="secondary">
                            Add Process

                        </Button> */}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-stages"
                            target="requirementId">

                            <Datagrid >
                                {propsObj.hasDeleteSelectionProcess && <VSDeleteButton {...props} /> }
                                {propsObj.hasEditSelectionProcess && <CustomEdit onClick={(record) => {
                                    setIsEditRecord(record); setIsEditStage(true);
                                }} label="Edit" />  }

                                <ReferenceField source="selectionStageId" reference="selection-stages" label="Selection Stage" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="selectionStageId" reference="selection-stages" label="Type" link={false}>
                                    <TextField source="type" />
                                </ReferenceField>
                                <ReferenceField source="sequenceId" reference="lookups" label="Selection Sequence" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <BooleanField source="isMailTrigger" label="Mail Trigger" />
                                <BooleanField source="isApplicableForInterviewer" label="Is Applicable For Interviewer" />                                
                                <BooleanField source="isAssignedToRecruiter" label="Is Applicable For Recruiter" />                                


                            </Datagrid>

                        </ReferenceManyField>
                        <FormDataConsumer>
                    {({ formData }) => {
                        setRequirementId(formData.id);
                        setLocationCityId(formData.locationCityId);
                        setWorkAuthorizationId(formData.workAuthorizationId);
                        return null;
                    }}
                </FormDataConsumer>
                
                
                
    
    
                    </FormTab>}
                    {propsObj.hasViewSkills && <FormTab value="Other" label="Skills">
                        {/* <Button variant="contained" onClick={() => setIsAddSkill(true)}
                            color="secondary">
                            Add Skill

                        </Button> */}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-skills-experiences"
                            target="requirementsId">

                            <Datagrid >
                                {/* <VSDeleteButton {...props} />
                                <CustomEdit onClick={(props) => {
                                 
                                    setIsEditRecord(props); setIsEditSkill(true);
                                }} label="Edit" />  */}

                                <ReferenceField source="skillId" reference="skills" label="Skill" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="experienceId" reference="experiences" label="Experiences" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                     {propsObj.hasViewRecruiters && <FormTab value="Other" label="Recruiters">
                        {/* <Button variant="contained" onClick={() => setIsAddRecruiter(true)}
                            color="secondary">
                            Add Recruiter
                        </Button> */}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="requirements-recruters"
                            target="requirementId">
                            <Datagrid >
                                {/* <VSDeleteButton {...props} /> */}
                                <ReferenceField source="recruiterId" reference="users" label="Recruiter" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" label="Remarks" />


                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                    
                   {propsObj.hasViewInterviewers && <FormTab value="Other" label="Interviewers">
                        
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }} 
                            reference="requirements-interviewers"
                            target="requirementId">
                            <Datagrid >
                                
                                <ReferenceField source="interviewerId" reference="users" label="Interviewer" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="remarks" label="Remarks" />
                               

                            </Datagrid>

                        </ReferenceManyField>
                    </FormTab>}
                   {propsObj.hasViewCandidates && <FormTab value="Other" label="Candidate">
                    {propsObj.hasCreateCandidates &&  <Button variant="contained" onClick={() => setIsAddCandidate(true)}
                            color="secondary">
                            Add Candidate
                        </Button> }
                        <ReferenceManyField perPage={10000} reference="requirements-details" target="requirementId" addLabel={false} fullWidth={true}> 
                            <List {...props}
                                title=" "
                                perPage={10000}
                                pagination={null}
                                bulkActionButtons={false}
                                exporter={false}
                                hasCreate={false}
                                reference="requirements-details"
                                filter={{requirementId: props.id}}  
                                filters={<ModuleFilter nameLabel={"Candidate "} />}
                            >
                                <Datagrid rowClick={false} id={"ABC"}>
                                {/* {propsObj.hasDeleteCandidates &&  <VSDeleteButton {...props} />} */}
                                    <ReferenceField source="candidateId" reference="candidates" label="Candidate" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="candidateId" reference="candidates" label="Email" link={false} >
                                        <TextField source="personnelEmailId" />
                                    </ReferenceField>
                                    <ReferenceField source="candidateId" reference="candidates" label="Contact No" link={false} >
                                        <TextField source="contactNo" />
                                    </ReferenceField>
                                    <ReferenceField source="candidateId" reference="candidates" label="SSNNO" link={false} >
                                        <TextField source="ssnno" />
                                    </ReferenceField>
                                    

                                    <ReferenceField source="currentStageId" reference="selection-stages" label="Current Stage" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="statusId" reference="lookups" label="Status" link={false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                        <TextField source="userName" />
                                    </ReferenceField>
                                        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                                    <CustomEdit title="Action"  />
                                </Datagrid>
                            </List>

                        </ReferenceManyField>
                    </FormTab>}



                </TabbedForm>
            </Edit>
            <Drawer anchor="right" onClose={onCancel} classes={{
                paper: myclasses.drawerPaper,
            }}
                open={isAddStage || isEditStage || isEditCandidate || isViewCandidate || isAddCandidate || isConfirmOnBoarding}>


                {isAddStage &&
                    <StageCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        subjectId={0}
                        {...props} />}

                {isEditStage && <StageEdit
                    onClose={onCloseForm}
                    onCancel={onCancel}
                    record={IsEditRecord}
                    subjectId={0}
                    {...props} />}

                {isEditCandidate &&
                    <CandidateActionCreate
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        requirementId={props.id}
                        {...props} />}

                {isViewCandidate &&
                    <CandidateActionView
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        requirementId={props.id}
                        {...props} />}

                {isAddCandidate &&
                    <EmployeeFields
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        requirementId={props.id}
                        locationCityId={locationCityId}
                        workAuthorizationId={workAuthorizationId}

                        {...props} />}
          

                {isConfirmOnBoarding &&
                    <CandidateOnBoarding
                        onClose={onCloseForm}
                        onCancel={onCancel}
                        record={IsEditRecord}
                        requirementId={props.id}
                        {...props} />}



            </Drawer>

        </>
    );
};
